import * as React from "react"
import Layout from "../components/layout"
import { GatsbyImage } from "gatsby-plugin-image"
import { graphql } from 'gatsby'
import {Col, Container, Row} from 'react-bootstrap'
import {Trans} from 'gatsby-plugin-react-i18next';

import ArrowRight from '../../static/arrow-left.png';

const About = ({ data }) => {
  const about = data.allContentfulAboutUsPage.edges[0].node
  const team = data.allContentfulStaff.edges
  return (
    <Layout title="About Varmaorka">
      <div className="py-offset">
        <Container>
          <Row className="align-items-center mb-md-5 mb-4 pb-md-2">
            <Col md={12}>
              <h2 className="section-title section-title-inner">{about.title}</h2>
            </Col>
          </Row>

          <div className="post-image">
            <GatsbyImage image={about.topImage.gatsbyImageData} alt={about.topImage.title} />
          </div>

          <div className="py-100">
            <h2 className="section-title mb-md-5 mb-4">{about.ourVisionHeading}</h2>
            <Row className="mb-3">
              <Col sm={10} md={7} className="pb-md-3 pb-1">
                <p className="vh_line">{about.ourVisionText1.ourVisionText1}</p>
              </Col>
            </Row>
          </div>

          <div className="py-100  pt-0">
            <h2 className="section-title mb-md-5 mb-4">{about.ourMissionHeading}</h2>
            <Row className="mb-3">
              <Col sm={10} md={7} className="pb-md-3 pb-1">
                <p className="vh_line">{about.ourMissionText.ourMissionText}</p>
              </Col>
            </Row>
          </div>

          <div className="py-100 pt-0">
            <Row className="mb-5">
              <Col md={6} className="pb-4">
                <h2 className="section-title">{about.unGoalsTitle}</h2>
              </Col>
              <Col md={12}>
                <h4 className="text-gray">{about.unGoalsSubtitle}</h4>
              </Col>
            </Row>

            <Row className="justify-content-between">
              <Col className="d-flex mb-md-5 mb-4" md={6} lg={6}>
                <div className="icon">
                  <img src={about.unGoal1Image.file.url} alt={about.unGoal1Image.title} />
                </div>
                <div className="icon-content">
                  <h3 className="mb-3">{about.unGoal1Heading}</h3>
                  <p className="vh_line">{about.unGoal1Text.unGoal1Text}</p>
                </div>
              </Col>
              <Col className="d-flex mb-md-5 mb-4" md={6} lg={6}>
                <div className="icon">
                  <img src={about.unGoal2Image.file.url} alt={about.unGoal2Image.title} />
                </div>
                <div className="icon-content">
                  <h3 className="mb-3">{about.unGoal2Heading}</h3>
                  <p className="vh_line">{about.unGoal2Text.unGoal2Text}</p>
                </div>
              </Col>
              <Col className="d-flex mb-md-5 mb-4" md={6} lg={6}>
                <div className="icon">
                  <img src={about.unGoal3Image.file.url} alt={about.unGoal3Image.title} />
                </div>
                <div className="icon-content">
                  <h3 className="mb-3">{about.unGoal3Heading}</h3>
                  <p className="vh_line">{about.unGoal3Text.unGoal3Text}</p>
                </div>
              </Col>
              <Col className="d-flex mb-md-5 mb-4" md={6} lg={6}>
                <div className="icon">
                  <img src={about.unGoal4Image.file.url} alt={about.unGoal4Image.title} />
                </div>
                <div className="icon-content">
                  <h3 className="mb-3">{about.unGoal4Heading}</h3>
                  <p className="vh_line">{about.unGoal4Text.unGoal4Text}</p>
                </div>
              </Col>
            </Row>
          </div>

          <div className="py-100 pt-0">
            <Row className="justify-content-center">
              <Col md={9}>
                <div className="section-title-middle text-center">
                  <h3>{about.teamText}</h3>
                </div>
              </Col>
            </Row>

            <Row>
              {team.map((member) => {
                return (
                  <Col md={4} className="mb-5">
                    <div className="team-card">
                      <GatsbyImage image={member.node.image.gatsbyImageData} alt={member.node.image.title} />
                      <div className="team-card-info text-end">
                        <h4>{member.node.name}</h4>
                        <p>{member.node.job}</p>
                      </div>

                      <div className="team-card-overlay d-flex h-100 flex-column w-100">
                        <div className="mb-auto text-end pb-3">
                          <h4>{member.node.name}</h4>
                          <div className="information">{member.node.email} <br />{member.node.phone === '0' ? null : member.node.phone}</div>
                        </div>
                        <p>{member.node.about.about}</p>
                      </div>
                    </div>
                  </Col>
                )
              })}
            </Row>
          </div>

          <div className="py-100 pt-0">
            <Row className="align-items-center mb-md-5 mb-4">
              <Col md={8}>
                <h2 className="section-title font-500">{about.cooperationHeading}</h2>
              </Col>
              <Col md={4} className="text-end d-lg-block d-none">
                <a rel="noreferrer" target="_blank" href='https://baseloadcap.com/' className="btn-link"><Trans>More about Baseload</Trans><img src={ArrowRight} alt="arrow-left" /></a>
              </Col>
            </Row>

            <Row className="align-items-center">
              <Col md={5} className="mb-md-0 mb-4 order-md-2">
                <GatsbyImage image={about.cooperationImage.gatsbyImageData} alt={about.cooperationImage.title} />
              </Col>
              <Col md={7}>
                <p className="vh_line">{about.cooperationText.cooperationText}</p>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </Layout>
  )
}

export const query = graphql`
query($language: String!){
  allContentfulAboutUsPage (filter: {node_locale: {eq: $language}}){
    edges {
      node {
        cooperationHeading
        cooperationImage {
          gatsbyImageData
        }
        cooperationText {
          cooperationText
        }
        ourVisionText1 {
          ourVisionText1
        }
        ourVisionHeading
        ourMissionText {
          ourMissionText
        }
        ourMissionHeading
        teamText
        title
        topImage {
          gatsbyImageData
        }
        unGoal1Heading
        unGoal1Image {
          file {
            url
          }
          title
        }
        unGoal1Text {
          unGoal1Text
        }
        unGoal2Heading
        unGoal2Image {
          file {
            url
          }
          title
        }
        unGoal2Text {
          unGoal2Text
        }
        unGoal3Heading
        unGoal3Image {
          file {
            url
          }
          title
        }
        unGoal3Text {
          unGoal3Text
        }
        unGoal4Heading
        unGoal4Image {
          title
          file {
            url
          }
        }
        unGoal4Text {
          unGoal4Text
        }
        unGoalsSubtitle
        unGoalsTitle
      }
    }
  }
  allContentfulStaff (sort: {fields: [order], order: ASC}, filter: {node_locale: {eq: $language}}){
    edges {
      node {
        email
        name
        job
        phone
        order
        image {
          gatsbyImageData
        }
        about {
          about
        }
      }
    }
  }
  locales: allLocale(filter: {language: {eq: $language}}) {
    edges {
      node {
        ns
        data
        language
      }
    }
  }
}`

export default About

